import React, {useMemo} from 'react';
import {Box, Container} from '@mui/material';
import {
	GENERIC_ENTRY_VIEW_ADD_ENTRY_REPOSITORY_OPTIONS,
	REPOSITORIES_BY_ENTITY_TYPE,
} from '../constants';
import {useAppSelector} from '../hooks';
import {selectCurrentSubEntries} from './repositoryEntryViewSlice';
import AddEntryForm, {AddEntryFormDialog, useAddEntryForm} from '../components/AddEntryForm';
import EditEntryForm, {EditFormDialog, useEditForm} from '../components/EditEntryForm';
import ConfirmDeleteEntryDialog, {useConfirmDeleteEntryDialog} from '../components/ConfirmDeleteEntryDialog';
import {entryGetReadableValue, getRepositorySingleUnitLabel} from '../common/util';
import {useGenericListItemMenu} from '../components/GenericListItemMenu';
import {Entry} from '../types';
import {selectIsAddDialogOpen, selectAddFormRepository} from './formDataSlice';
import {useContentNavigationEffects} from '../components/ContentNavigationEffects';
import {useGenericEntryKeyboardEffects} from '../components/KeyboardEffects';
import EntriesList, { EntriesListItem } from '../components/EntriesList';
import {selectEntryReadableValues} from './contentSlice';
import { RowMenuOption, RowMenuOptionGoTo, RowMenuItem, RowMenuOptionEdit, RowMenuOptionDelete, RowMenuOptionClip } from '../components/EntryMenu';
import { useContentWrapper } from './ContentWrapper';

function GenericEntryView() {
	const locationSubEntries = useAppSelector(selectCurrentSubEntries);
	const addFormRepository = useAppSelector(selectAddFormRepository);
	const isAddDialogOpen = useAppSelector(selectIsAddDialogOpen);
	const {openAddDialog, closeAddDialog, handleAddDialogValueChange, onAddDialogSubmit} = useAddEntryForm();
	const {isEditDialogOpen, editFormEntry, closeEditDialog, onEditFormSubmit} = useEditForm();
	const {isConfirmDeleteDialogOpen, confirmDeleteDialogEntry, closeConfirmDeleteDialog, submitConfirmDeleteDialog} = useConfirmDeleteEntryDialog();
	const {menuAnchorEl, setMenuAnchorEl, menuEntry, openListItemMenu, closeListItemMenu, handleMenuEditBtnClick, handleMenuDeleteBtnClick, handleMenuGoToBtnClick, handleMenuCutBtnClick} = useGenericListItemMenu({ entries: locationSubEntries as Entry[] });
	const {focusIdRef, selectedEntryId, handleEntryClick, handleEntryFocus, handleEntryBlur} = useContentNavigationEffects();

	useGenericEntryKeyboardEffects({ entries: locationSubEntries as Entry[], focusIdRef });

	const editDialogTitle = useMemo(() => editFormEntry ? `Edit ${getRepositorySingleUnitLabel(REPOSITORIES_BY_ENTITY_TYPE[editFormEntry.type]).toLowerCase()}?` : '', [editFormEntry]);
	const confirmDeleteDialogTitle = useMemo(() => confirmDeleteDialogEntry ? `Delete ${getRepositorySingleUnitLabel(REPOSITORIES_BY_ENTITY_TYPE[confirmDeleteDialogEntry.type]).toLowerCase()}?` : '', [confirmDeleteDialogEntry]);
	const entryReadableValues = useAppSelector(selectEntryReadableValues);
	const {isContentLoaded} = useContentWrapper();

	const rowMenuOptions: RowMenuOption[] = [
		{
			content: RowMenuOptionGoTo,
			onClick: (item: RowMenuItem) => handleMenuGoToBtnClick(item.id),
			contentProps: {keyboardShortcut: true},
			key: 'goToOption',
		},{
			content: RowMenuOptionEdit,
			onClick: (item: RowMenuItem) => handleMenuEditBtnClick(item.id),
			contentProps: {keyboardShortcut: true},
			key: 'editOption',
		}, {
			content: RowMenuOptionClip,
			onClick: (item: RowMenuItem) => handleMenuCutBtnClick(item.id),
			key: 'clipOption',
		}, {
			content: RowMenuOptionDelete,
			onClick: (item: RowMenuItem) => handleMenuDeleteBtnClick(item.id),
			contentProps: {keyboardShortcut: true},
			key: 'deleteOption',
		}, 
	];

	return isContentLoaded ? <>
		<Box display='flex' justifyContent='center' flexDirection='column' flex={1}>
			<Container maxWidth={false}>
				<EntriesList rowMenuProps={{
					options: rowMenuOptions,
					anchorEl: menuAnchorEl,
					onClose: closeListItemMenu,
					item: menuEntry,
				}}>
					{locationSubEntries
						.map((entry) => (
							<EntriesListItem
								key={entry.id}
								id={entry.id}
								label={entryGetReadableValue(entry, entryReadableValues)}
								onMenuBtnClick={() => openListItemMenu(entry)}
								selectedEntryId={selectedEntryId}
								onRowClick={() => handleEntryClick(entry.id)}
								onEntryFocus={handleEntryFocus}
								onEntryBlur={handleEntryBlur}
								setMenuAnchorEl={setMenuAnchorEl}
							/>))
					}
				</EntriesList>
			</Container>
		</Box>
		<AddEntryFormDialog
			open={isAddDialogOpen}
			onClose={closeAddDialog}
			onSubmit={onAddDialogSubmit}
			repositoryOptions={GENERIC_ENTRY_VIEW_ADD_ENTRY_REPOSITORY_OPTIONS}
			onRepositoryOptionChange={(repositoryId) => openAddDialog({ repositoryId })}
			title={addFormRepository ? `New ${(addFormRepository as {singular:string}).singular.toLowerCase()}` : ''}
		>
			<AddEntryForm
				onChange={handleAddDialogValueChange}
			/>
		</AddEntryFormDialog>
		<EditFormDialog
			open={isEditDialogOpen}
			handleClose={closeEditDialog}
			handleSubmit={onEditFormSubmit}
			title={editDialogTitle}
		>
			<EditEntryForm />
		</EditFormDialog>
		<ConfirmDeleteEntryDialog
			open={isConfirmDeleteDialogOpen}
			handleClose={closeConfirmDeleteDialog}
			handleSubmit={submitConfirmDeleteDialog}
			entry={confirmDeleteDialogEntry}
			title={confirmDeleteDialogTitle}
		/>
	</> : null;
}

export default GenericEntryView;