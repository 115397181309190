import React, { useEffect, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import EditIcon from '@mui/icons-material/Edit';
import ReorderIcon from '@mui/icons-material/Reorder';
import ContentCutIcon from '@mui/icons-material/ContentCut';
import ListItemText from '@mui/material/ListItemText';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import SubdirectoryArrowLeftIcon from '@mui/icons-material/SubdirectoryArrowLeft';
import VerticalAlignTopIcon from '@mui/icons-material/VerticalAlignTop';
import ArchiveIcon from '@mui/icons-material/Archive';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import {Menu, Typography} from '@mui/material';
import { css } from '@emotion/css';

export enum RowMenuOptionEffect {
	Reorder = 'reorder',
	MoveToTop = 'moveToTop'
}

export type RowMenuItem = object & {id: string, isArchived?: boolean};

interface RowMenuOptionProps {keyboardShortcut?: boolean}

export interface RowMenuOption {
	content: React.FC<RowMenuOptionProps>;
	onClick: ((arg: RowMenuItem) => void) | RowMenuOptionEffect;
	contentProps?: RowMenuOptionProps;
	displayCondition?: (item: RowMenuItem) => boolean,
	key: string,
}

export interface RowMenuProps {
	options: RowMenuOption[];
	anchorEl: Element | null;
	onMenuBtnClick?: (item: RowMenuItem, el?: Element) => void;
	onRowReorderClick?: (item: RowMenuItem) => void;
	onRowMoveToTopClick?: (item: RowMenuItem) => void;
	onClose: () => void;
	item: RowMenuItem | null;
}

export const RowMenuOptionArchive = () => <>
	<ListItemIcon>
		<ArchiveIcon fontSize="small" />
	</ListItemIcon>
	<ListItemText>Archive</ListItemText>
</>;

export const RowMenuOptionUnarchive = () => <>
	<ListItemIcon>
		<UnarchiveIcon fontSize="small" />
	</ListItemIcon>
	<ListItemText>Unarchive</ListItemText>
</>;

export const RowMenuOptionDelete = ({keyboardShortcut}: RowMenuOptionProps) => <>
	<ListItemIcon>
		<DeleteForeverIcon fontSize="small" />
	</ListItemIcon>
	<ListItemText>Delete</ListItemText>
	{keyboardShortcut && <Typography variant="body2">
		backspace
	</Typography>}
</>;

export const RowMenuOptionGoTo = ({keyboardShortcut}: RowMenuOptionProps) => <>
	<ListItemIcon>
		<SubdirectoryArrowLeftIcon fontSize="small" />
	</ListItemIcon>
	<ListItemText>Go to</ListItemText>
	{keyboardShortcut && <Typography variant="body2">
	enter
	</Typography>}
</>;

export const RowMenuOptionEdit = ({keyboardShortcut}: RowMenuOptionProps) => <>
	<ListItemIcon>
		<EditIcon fontSize="small" />
	</ListItemIcon>
	<ListItemText>Edit</ListItemText>
	{keyboardShortcut && <Typography variant="body2">
	spacebar
	</Typography>}
</>;

export const RowMenuOptionReorder = ({keyboardShortcut}: RowMenuOptionProps) => <>
	<ListItemIcon>
		<ReorderIcon fontSize="small" />
	</ListItemIcon>
	<ListItemText>Reorder</ListItemText>
</>;

export const RowMenuOptionClip = ({keyboardShortcut}: RowMenuOptionProps) => <>
	<ListItemIcon>
		<ContentCutIcon fontSize="small" />
	</ListItemIcon>
	<ListItemText>Cut</ListItemText>
	{keyboardShortcut && <Typography variant="body2">
		⌘X
	</Typography>}
</>;

export const RowMenuOptionMoveToTop = () => <>
	<ListItemIcon>
		<VerticalAlignTopIcon fontSize="small" />
	</ListItemIcon>
	<ListItemText>Move to top</ListItemText>
</>;

function EntryMenu({anchorEl, item, options, onClose, onRowReorderClick, onRowMoveToTopClick}: RowMenuProps) {
	if (!anchorEl) {
		return null;
	}

	// TODO: this caching causes: Warning: Internal React error: Expected static flag was missing. Please notify the React team.
	const [cachedItem, setCachedItem] = useState<RowMenuItem | null>(null);
	useEffect(() => {
		if (item) {
			setCachedItem(item);
		}
	}, [item]);

	return <Menu
		id="demo-positioned-menu"
		aria-labelledby="demo-positioned-button"
		anchorEl={anchorEl}
		open={Boolean(item)}
		onClose={() => onClose()}
		anchorOrigin={{
			vertical: 'top',
			horizontal: 'left',
		}}
		className={css`
				& .MuiPaper-root {
					min-width: 240px;
				}
			`}
		transformOrigin={{
			vertical: 'top',
			horizontal: 'left',
		}}
	>
		{options
			.filter(({displayCondition}) => (cachedItem && displayCondition) ? displayCondition(cachedItem) : true)
			.map(({ content, onClick, contentProps, key }, i) => {
				const MenuItemContent = content;
				if (typeof onClick === 'function') {
					return <MenuItem key={key} onClick={() => onClick(cachedItem!)}><MenuItemContent {...(contentProps ? contentProps : {})} /></MenuItem>;
				}
				if (onClick === RowMenuOptionEffect.Reorder) {
					return <MenuItem key={key} onClick={onRowReorderClick ? () => onRowReorderClick(cachedItem!) : undefined}><MenuItemContent {...(contentProps ? contentProps : {})} /></MenuItem>;
				}
				if (onClick === RowMenuOptionEffect.MoveToTop) {
					return <MenuItem key={key} onClick={onRowMoveToTopClick ? () => onRowMoveToTopClick(cachedItem!) : undefined}><MenuItemContent {...(contentProps ? contentProps : {})} /></MenuItem>;
				}
			})}
	</Menu>;
}

export default EntryMenu;