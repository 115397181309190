import React from 'react';
import {Repository, Theme} from '../types';
import {useMediaQuery, Box, Button, useTheme} from '@mui/material';
import {RepositoryHeaderTabs} from './RepositoryHeaderTabs';
import {useAppSelector} from '../hooks';
import {selectLocationEntry} from '../containers/contentSlice';
import {css} from '@emotion/css';
import { useContentWrapper } from '../containers/ContentWrapper';

function RepositoryHeaderContent() {
	const {shouldRenderRepositoryView, isContentLoaded} = useContentWrapper();
	const locationEntry = useAppSelector(selectLocationEntry);
	const isXsScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
	const theme = useTheme();
	const classes = {
		titleLink: css`
		white-space: nowrap;
		overflow: hidden;
		text-align: left;
	`};

	if (!shouldRenderRepositoryView || !isContentLoaded) {
		return null;
	}

	return <>
		{locationEntry && <Box className={classes.titleLink}><Button disabled style={{color: theme.palette.text.primary}}>
			{(locationEntry as Repository).title}
		</Button></Box>}
		{!isXsScreen ? <Box>
			<RepositoryHeaderTabs />
			<span>&nbsp;</span>
		</Box> : null}
	</>;
}

export default RepositoryHeaderContent;
