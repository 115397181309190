import React, {useEffect} from 'react';
import {Repository, RepositoryViewTab, Theme} from '../types';
import RepositoryViewProperties from './RepositoryViewProperties';
import RepositoryViewEntries from './RepositoryViewEntries';
import {useAppSelector} from '../hooks';
import {Box, useMediaQuery} from '@mui/material';
import { RepositoryHeaderTabs } from '../components/RepositoryHeaderTabs';
import {
	selectLocationEntryLocal,
	repositoryLoaded,
} from './contentSlice';
import {
	selectIsRepositoryLoading,
	selectRepository,
	selectRepositoryPropertyDefinitions,
}
	from './repositoryViewSlice';
import {useLoadRepositoryMutation} from '../services/repositoryEntries';
import {selectLocationId} from './contentSlice';
import useNavigateToLocationId from '../components/NavigateToLocationId';
import {selectTab, tabSetState, resetState} from './repositoryViewSlice';
import {useDispatch} from 'react-redux';
import useAuth from '../components/Auth';
import {Helmet} from 'react-helmet';
import config from '../common/config';
import { useContentWrapper } from './ContentWrapper';

const RepositoryView = () => {
	const {isAuthenticated, isAuthenticating} = useAuth();
	const navigateToLocationId = useNavigateToLocationId();
	const dispatch = useDispatch();
	const locationId = useAppSelector(selectLocationId);
	const repository = useAppSelector(selectRepository);
	const isRepositoryLoading = useAppSelector(selectIsRepositoryLoading);
	const repositoryPropertyDefinitions = useAppSelector(selectRepositoryPropertyDefinitions);
	const viewTab = useAppSelector(selectTab);
	const [loadRepositoryMutation, {error: loadRepositoryError}] = useLoadRepositoryMutation();
	const isXsScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
	const locationEntryLocal = useAppSelector(selectLocationEntryLocal);
	const {isContentLoaded} = useContentWrapper();

	/**
	 * on view dismount, reset repository view state
	 */
	useEffect(() => {
		return () => {
			dispatch(resetState());
		};
	}, []);

	/**
	 * on repository loaded, default to Template tab in case no properties exist
	 */
	useEffect(() => {
		if (repository && !repositoryPropertyDefinitions.length) {
			dispatch(tabSetState(RepositoryViewTab.Template));
		}
	}, [repository, repositoryPropertyDefinitions]);

	/**
	 * while authenticated
	 */
	useEffect(() => {
		if (!isAuthenticated || isAuthenticating) {
			return;
		}
		if (!repository && !isRepositoryLoading) {
			loadRepositoryMutation(locationId as string);
		}
	}, [isAuthenticated, isAuthenticating, repository, isRepositoryLoading, locationId]);

	/**
	 * while not authenticated, mock data load
	 */
	useEffect(() => {
		if (isAuthenticated || isAuthenticating) {
			return;
		}
		if (!repository && locationEntryLocal) {
			dispatch(repositoryLoaded(locationEntryLocal as Repository));
		}
	}, [isAuthenticating, isAuthenticated, repository, locationEntryLocal]);

	/**
	 * on failing to load repository info, navigate to root location
	 */
	useEffect(() => {
		if (loadRepositoryError) {
			navigateToLocationId(null);
		}
	}, [loadRepositoryError]);

	return isContentLoaded ? <>
		{repository && <Helmet>
			<title>{repository.title} - {config.appName}</title>
		</Helmet>}
		{isXsScreen && <Box display='flex' justifyContent='right' sx={{m: 1}}>
			<RepositoryHeaderTabs />
		</Box>}
		{[RepositoryViewTab.Entries, RepositoryViewTab.Archive].includes(viewTab) && repository && <RepositoryViewEntries repository={repository} />}
		{viewTab === RepositoryViewTab.Template && repository && <RepositoryViewProperties />}
	</> : null;
};

export default RepositoryView;
