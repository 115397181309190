import React from 'react';
import {Entry} from '../types';
import {useEditForm} from './EditEntryForm';
import {useConfirmDeleteEntryDialog} from './ConfirmDeleteEntryDialog';
import {useClipboard} from './ClipboardActionsFab';
import useNavigateToLocationId from './NavigateToLocationId';

interface UseGenericListMenuEffectProps {
	entries: Entry[];
}

export function useGenericListItemMenu({ entries }: UseGenericListMenuEffectProps) {
	const {openEditDialog} = useEditForm();
	const {openConfirmDeleteDialog} = useConfirmDeleteEntryDialog();
	const {cutEntry} = useClipboard();
	const navigateToLocationId = useNavigateToLocationId();

	const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | Element>(null);
	const [menuEntry, setMenuEntry] = React.useState<null | Entry>(null);

	const openListItemMenu = (entry: Entry) => setMenuEntry(entry);
	const closeListItemMenu = () => setMenuEntry(null);

	const handleMenuEditBtnClick = (id: string) => {
		closeListItemMenu();
		const entry = entries.find((entry) => entry.id === id);
		if (entry) {
			openEditDialog(entry);
		}
	};

	const handleMenuDeleteBtnClick = (id: string) => {
		closeListItemMenu();
		const entry = entries.find((entry) => entry.id === id);
		if (entry) {
			openConfirmDeleteDialog(entry as Entry);
		}
	};

	const handleMenuGoToBtnClick = (id: string) => {
		closeListItemMenu();
		navigateToLocationId(id);
	};

	const handleMenuCutBtnClick = (id: string) => {
		closeListItemMenu();
		cutEntry(id);
	};

	return {menuAnchorEl, setMenuAnchorEl, menuEntry, setMenuEntry, openListItemMenu, closeListItemMenu, handleMenuEditBtnClick, handleMenuDeleteBtnClick, handleMenuGoToBtnClick, handleMenuCutBtnClick};
}
