import { Alert, Box, Button, Card, Dialog, DialogContent, DialogContentText, DialogTitle, Divider, Typography } from '@mui/material';
import React from 'react';
import { isIntroDialogOpenSetState, selectIsIntroDialogOpen } from '../containers/authSlice';
import { useAppDispatch, useAppSelector } from '../hooks';
import { localStorageSetIsIntroDialogCompleted } from '../localStorage';
import { JOURNAL_DATA_PRESET } from '../dataPresets/journal';
import { selectAllEntries } from '../containers/contentSlice';
import { useEntryImportMutation } from '../services/records';

const IntroDialog = () => {
	const dispatch = useAppDispatch();
	const [entrySyncMutation] = useEntryImportMutation();
	const allEntries = useAppSelector(selectAllEntries);
	const isIntroDialogOpen = useAppSelector(selectIsIntroDialogOpen);
	const isDataPresetsDisabled = allEntries.length > 0;

	const handleClose = () => {
		localStorageSetIsIntroDialogCompleted(true);
		dispatch(isIntroDialogOpenSetState(false));
	};

	const handleClick = () => {
		localStorageSetIsIntroDialogCompleted(true);
		entrySyncMutation(JOURNAL_DATA_PRESET);
		dispatch(isIntroDialogOpenSetState(false));
	};

	return <Dialog open={isIntroDialogOpen} title="Intro" fullWidth maxWidth='sm'>
		<DialogTitle>Well met!</DialogTitle>
		<DialogContent>
			<Typography>You will be able to set up some powerful data collections on this app, but we&apos;ll get to that. For starters, pick a simple preset to begin with:</Typography>
			<Button variant='contained' sx={{mt: 2, mb: 3}} onClick={handleClick} disabled={allEntries.length > 0}>Journaling</Button>
			{isDataPresetsDisabled && <Alert severity="warning">For safety reasons, you can&apos;t initiate data presets while there are already some entries in the database.</Alert>}
			<Divider />
			<Button sx={{mt: 2}} onClick={handleClose}>No thank you, I know what Im doing!</Button>
		</DialogContent>
	</Dialog>;
};

export default IntroDialog;
