import * as React from 'react';
import {ReactNode, useCallback, useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import {EntityType, Entry, PopulatedEntry, PropertyDefinition} from '../types';
import {Box, DialogTitle} from '@mui/material';
import FormTemplate from './FormTemplate';
import {
	entriesUpdateMany,
	repositoryEntryUpdateLocalAction,
	selectAllEntries,
	selectAllRepositoryEntries,
	selectEntryReadableValues,
} from '../containers/contentSlice';
import {useRepositoryEntryUpdateMutation} from '../services/repositoryEntries';
import {useDispatch} from 'react-redux';
import {useAppSelector} from '../hooks';
import {initFormData, populateSelectOptionsRepositories, populateSelectOptionsRepositoryEntries, populateEntryData} from '../common/util';
import {useEntryUpdateMutation} from '../services/records';
import {selectIsAuthenticated} from '../containers/authSlice';
import {
	editFormEntrySetState,
	formDataSetState,
	formInit,
	selectEditFormEntry,
	selectEditFormEntryPropertyDefinitions,
	selectFormData,
	selectFormSelectOptions,
	selectFormTemplateProperties,
	selectIsEditDialogOpen,
} from '../containers/formDataSlice';

export type FormData = Record<string, any>; // TODO: use ModelTemplate?

interface Props {
	displayRelationsAsTables?: boolean;
	readOnly?: boolean;
	onSelectOptionCreateClick?: (def: PropertyDefinition) => void;
}

interface DialogProps {
	open: boolean;
	handleClose: () => void;
	handleSubmit: (formData: FormData) => void;
	title: string;
	children: ReactNode;
}

export const useEditForm = () => {
	const dispatch = useDispatch();
	const isEditDialogOpen = useAppSelector(selectIsEditDialogOpen);
	const editFormEntry = useAppSelector(selectEditFormEntry);
	const isAuthenticated = useAppSelector(selectIsAuthenticated);
	const [entryUpdateMutation] = useEntryUpdateMutation();
	const [repositoryEntryUpdateMutation] = useRepositoryEntryUpdateMutation();

	const openEditDialog = (entry: Entry) => {
		dispatch(editFormEntrySetState(entry));
	};

	const closeEditDialog = () => dispatch(editFormEntrySetState(null));

	const onEditFormSubmit = (payload: FormData) => {
		if (isAuthenticated) {
			entryUpdateMutation([{ ...payload, id: (editFormEntry as Entry).id }] as Entry[]);
		} else {
			dispatch(entriesUpdateMany([{ ...payload, id: (editFormEntry as Entry).id }]));
		}
		closeEditDialog();
	};

	const onEditFormSubmitEntry = (payload: FormData) => {
		const {...propertyValues} = payload; // TODO: type doesn't need to come from the payload
		if (isAuthenticated) {
			repositoryEntryUpdateMutation({ id: editFormEntry!.id, values: propertyValues});
		} else {
			dispatch(repositoryEntryUpdateLocalAction({ id: editFormEntry!.id, values: propertyValues }));
		}
		closeEditDialog();
	};

	return {isEditDialogOpen, editFormEntry, openEditDialog, closeEditDialog, onEditFormSubmit, onEditFormSubmitEntry};
};

export const EditFormDialog: React.FC<DialogProps> = ({open, handleClose, handleSubmit, title, children}) => {
	const entry = useAppSelector(selectEditFormEntry);
	const formData = useAppSelector(selectFormData);
	const formTemplateProperties = useAppSelector(selectFormTemplateProperties);
	const [dialogTitle, setDialogTitle] = useState<string | null>(null);

	// cache content to prevent it from disappearing during dialog fade out
	useEffect(() => {
		if (open) {
			setDialogTitle(title);
		}
	}, [open]);

	const onSubmit = (e: React.FormEvent) => {
		e.preventDefault();
		if (entry!.type === EntityType.RepositoryEntry) {
			handleSubmit(formData);
		} else {
			// temporarily transform to flat structure
			const submitData = formTemplateProperties.reduce((acc, def) => {
				if (def.type === EntityType.PropertyDefinitionBelongsTo) {
					return { ...acc, [def.slug]: (formData[def.slug] as string[])[0] };
				}
				return acc;
			}, formData);
			handleSubmit(submitData);
		}
	};

	return <Dialog open={open} onClose={handleClose} sx={{
		'& .MuiDialog-container': {
			'& .MuiPaper-root': {
				width: '100%',
			},
		},
	}}>
		<Box display='flex' justifyContent='space-between' alignItems='center'>
			<DialogTitle>{dialogTitle}</DialogTitle>
		</Box>
		<form onSubmit={onSubmit} autoComplete='off'>
			<DialogContent>
				{children}
			</DialogContent>
			<DialogActions color='secondary'>
				<Button onClick={handleClose}>Cancel</Button>
				<Button type='submit' variant="contained">Update</Button>
			</DialogActions>
		</form>
	</Dialog>;
};

function EditEntryForm({ readOnly, displayRelationsAsTables, onSelectOptionCreateClick}: Props) {
	const entry = useAppSelector(selectEditFormEntry);
	const formData = useAppSelector(selectFormData);
	const formTemplateProperties = useAppSelector(selectFormTemplateProperties);
	const formSelectOptions = useAppSelector(selectFormSelectOptions);
	const dispatch = useDispatch();
	const allEntries = useAppSelector(selectAllEntries);
	const allRepositoryEntries = useAppSelector(selectAllRepositoryEntries);
	const entryReadableValues = useAppSelector(selectEntryReadableValues);
	const propertyDefinitions = useAppSelector(selectEditFormEntryPropertyDefinitions);

	const initForm = (entry: Entry) => {
		const populatedEntry = entry.type === EntityType.RepositoryEntry ? entry : populateEntryData(entry);
		const formSelectOptions = entry.type === EntityType.RepositoryEntry
			? populateSelectOptionsRepositoryEntries(propertyDefinitions, allRepositoryEntries, entryReadableValues)
			: populateSelectOptionsRepositories(propertyDefinitions, allEntries);
		const formData = initFormData(propertyDefinitions, (populatedEntry as PopulatedEntry).data);
		dispatch(formInit({
			formTemplateProperties: propertyDefinitions,
			formSelectOptions,
			formData,
		}));
	};

	useEffect(() => {
		if (entry) {
			initForm(entry);
		}
	}, [entry]);

	const onValueChange = useCallback((key: string, value: any) => {
		if (value !== ' ') {
			dispatch(formDataSetState({ ...formData, [key]: value }));
		}
	}, [formData]);

	return (
		<FormTemplate
			propertyDefinitions={formTemplateProperties}
			onValueChange={onValueChange}
			formData={formData}
			selectOptions={formSelectOptions}
			displayRelationsAsTables={displayRelationsAsTables}
			onSelectOptionCreateClick={onSelectOptionCreateClick}
		/>
	);
}

export default EditEntryForm;
