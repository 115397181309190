import {api} from './api';
import {Entry} from '../types';
import {entriesAddMany, repositoryLoading, repositoryEntryLoading, entriesDeleteMany, entriesUpdateMany} from '../containers/contentSlice';

export const repositoryEntriesApi = api.injectEndpoints({
	endpoints: (builder) => ({
		loadRepository: builder.mutation({
			query: (repositoryId: string) => ({
				url: `repositories/${repositoryId}`,
				method: 'GET',
				params: {
					includeArchived: true
				}
			}),
			async onQueryStarted(arg: string, { dispatch }: any) {
				dispatch(repositoryLoading());
			},
			extraOptions: { maxRetries: 0 }
		}),
		loadRepositoryEntry: builder.mutation({
			query: (entryId: string) => ({
				url: `repositoryEntries/${entryId}`,
				method: 'GET',
			}),
			async onQueryStarted(arg: string, { dispatch }: any) {
				dispatch(repositoryEntryLoading());
			},
			extraOptions: { maxRetries: 0 }
		}),
		repositoryEntryCreate: builder.mutation({
			query: (payload: { entry: Entry, properties: Entry[], foreignEntryProperties: Entry[] }) => ({
				url: 'repositoryEntries',
				method: 'POST',
				body: payload,
			}) as any,
			async onQueryStarted(
				arg: { entry: Entry, properties: Entry[], foreignEntryProperties: Entry[] },
				{
					dispatch,
				}: any
			) {
				dispatch(entriesAddMany([arg.entry, ...arg.properties, ...arg.foreignEntryProperties]));
			},
			extraOptions: { maxRetries: 0 }
		} as any),
		repositoryEntryUpdate: builder.mutation({
			query: (payload: { id: string, values: any}) => ({
				url: `repositoryEntries/${payload.id}`,
				method: 'PUT',
				body: payload.values,
			}) as any,
			extraOptions: { maxRetries: 0 }
		} as any),
		repositoryEntryArchive: builder.mutation({
			query: (entryId: string) => ({
				url: `repositoryEntries/${entryId}/archive`,
				method: 'PUT',
			}) as any,
			async onQueryStarted(entryId: string, { dispatch }: any) {
				dispatch(entriesUpdateMany([{ id: entryId, isArchived: true }]));
			},
			extraOptions: { maxRetries: 0 }
		} as any),
		repositoryEntryUnarchive: builder.mutation({
			query: (entryId: string) => ({
				url: `repositoryEntries/${entryId}/unarchive`,
				method: 'PUT',
			}) as any,
			async onQueryStarted(entryId: string, { dispatch }: any) {
				dispatch(entriesUpdateMany([{ id: entryId, isArchived: false }]));
			},
			extraOptions: { maxRetries: 0 }
		} as any),
	}),
});

export const { useLoadRepositoryMutation, useLoadRepositoryEntryMutation, useRepositoryEntryCreateMutation, useRepositoryEntryUpdateMutation, useRepositoryEntryArchiveMutation, useRepositoryEntryUnarchiveMutation } = repositoryEntriesApi;
