import * as React from 'react';
import {useCallback, useEffect, useRef, useState} from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {
	BelongsToPopulatedValue,
	EntityType,
	Entry,
	PopulatedEntry,
	PropertyDefinition,
	PropertyDefinitionBelongsTo, Theme
} from '../types';
import {Box, Button, Chip, IconButton, useMediaQuery, useTheme} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {useDispatch} from 'react-redux';
import {entriesUpdateMany} from '../containers/contentSlice';
import {css} from '@emotion/css';
import {entryPropertyGetReadableValue} from '../common/util';
import {useEntryUpdateMutation} from '../services/records';
import {useAppSelector} from '../hooks';
import {selectIsAuthenticated} from '../containers/authSlice';
import EntryMenu, { RowMenuProps } from './EntryMenu';

const REORDER_ACTIVATE_TOUCH_TIMEOUT = 500;
const CHIP_MAX_WIDTH = '240px';
const TEXT_MAX_WIDTH = '320px';
const TEXT_MAX_WIDTH_MD_UP = '640px';

type EntriesTableRowMenuProps = Omit<RowMenuProps,'onRowReorderClick'>

interface Props {
	data: PopulatedEntry[];
    propertyDefinitions: PropertyDefinition[];
	rowMenuProps: EntriesTableRowMenuProps;
	disableReordering?: boolean;
	tableFooterContent?: React.ReactNode;
}

interface TitleCellContentProps {
	def: PropertyDefinition;
	value: any;
	onTouchStart: (e: React.TouchEvent<HTMLTableCellElement>) => void;
	onTouchEnd: (e: React.TouchEvent<HTMLTableCellElement>) => void;
	onMouseDown: (e: React.MouseEvent<HTMLTableCellElement>) => void;
	onReorderBtnTouchEnd?: (e: React.TouchEvent<HTMLButtonElement>) => void;
	disabled?: boolean;
}

interface CellContentProps {
	def: PropertyDefinition;
	value: any;
	disabled?: boolean;
}

const throttle = (func: (...args: any) => any, wait: number) => {
	let lastTime = 0;
	return (...args: any) => {
		const now = new Date().getTime();
		if (now - lastTime >= wait) {
			lastTime = now;
			func(...args);
		}
	};
};

const TitleCellContent = ({def, onTouchStart, onTouchEnd, onMouseDown, onReorderBtnTouchEnd, value, disabled = false}: TitleCellContentProps) => {
	const classes = {
		textOverflowEllipsis: css`
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		`,
	};
	const isScreenMdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
	const contentMaxWidth = isScreenMdUp ? TEXT_MAX_WIDTH_MD_UP : TEXT_MAX_WIDTH;
	const disabledColor = disabled ? 'action.disabled' : undefined;
	return <TableCell
		onTouchEnd={onTouchEnd}
		sx={{p: 0}}
		className={classes.textOverflowEllipsis}
	>
		<Box display='flex'>
			<Box
				onMouseDown={disabled ? undefined : onMouseDown}
				onTouchStart={disabled ? undefined : onTouchStart}
				onTouchEnd={disabled ? undefined : onTouchEnd}
				sx={{cursor: disabled ? undefined : 'pointer', userSelect: 'none', p:2, maxWidth: contentMaxWidth, color: disabledColor}}
				className={classes.textOverflowEllipsis}
			>
				{entryPropertyGetReadableValue(def, value)}
			</Box>
		</Box>
	</TableCell>;
};

const CellContent = ({def, value, disabled}: CellContentProps) => {
	const classes = {
		textOverflowEllipsis: css`
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		`,
	};
	const isScreenMdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
	const contentMaxWidth = isScreenMdUp ? TEXT_MAX_WIDTH_MD_UP : TEXT_MAX_WIDTH;
	const disabledColor = disabled ? 'action.disabled' : undefined;
	if (def.type === EntityType.PropertyDefinitionHas || def.type === EntityType.PropertyDefinitionBelongsTo && (def as PropertyDefinitionBelongsTo).allowMany) {
		return <TableCell>
			<Box sx={{userSelect: 'text'}}>
				{value.slice(0, 2).map((val: BelongsToPopulatedValue) => (
					<Chip key={val.value} label={val.label} sx={{m: 0.25, cursor: 'default', maxWidth: CHIP_MAX_WIDTH, color: disabledColor}} className={classes.textOverflowEllipsis} />
				))}
				{value.length > 2 && (
					<Chip
						label={`+${value.length - 2} more`}
						sx={{m: 0.25, cursor: 'default', color: disabledColor}}
					/>
				)}
			</Box>
		</TableCell>;
	}
	return <TableCell>
		<Box sx={{userSelect: 'text', maxWidth: contentMaxWidth, color: disabledColor}} className={classes.textOverflowEllipsis}>
			{entryPropertyGetReadableValue(def, value)}
		</Box>
	</TableCell>;
};

function EntriesTable({data, propertyDefinitions, rowMenuProps, disableReordering, tableFooterContent}: Props) {
	const [dragItem, setDragItem] = useState<any>(null);
	const [touchItem, setTouchItem] = useState<any>(null);
	const dispatch = useDispatch();
	const [entryUpdateMutation] = useEntryUpdateMutation();
	const isAuthenticated = useAppSelector(selectIsAuthenticated);
	const updatesRef = useRef<Partial<Entry>[]>([]);
	const hasWindowScrolledRef = useRef<boolean>(false);
	const rowHoldTimeout = useRef<NodeJS.Timeout | null>(null);

	/** MOUSE EVENT:
	 * while reordering is in progress, cancel reordering and publish rank updates by releasing mouse
	 */
	const handleMouseUp = useCallback(() => {
		setDragItem(null);
		if (isAuthenticated) {
			pushReorderUpdates();
		}
	}, [data]);

	const handleScroll = useCallback(() => {
		hasWindowScrolledRef.current = true;
	}, []);
	const throttledScrollHandler = throttle(handleScroll, 100);

	useEffect(() => {
		window.addEventListener('scroll', throttledScrollHandler);
		return () => {
			window.removeEventListener('scroll', throttledScrollHandler);
		};
	}, [throttledScrollHandler]);

	useEffect(() => {
		window.addEventListener('mouseup', handleMouseUp);
		return () => {
			window.addEventListener('mouseup', handleMouseUp);
		};
	}, [handleMouseUp]);

	// TODO: duplicate code
	const applyLocalOrder = (e: any, targetItem: any) => {
		const reorderItem = dragItem ?? touchItem;
		if (!reorderItem) return;

		const newItems: any = [...data];
		const draggingIndex = data.findIndex((item) => item.id === reorderItem.id);
		const targetIndex = data.findIndex((item) => item.id === targetItem.id);

		newItems.splice(draggingIndex, 1);
		newItems.splice(targetIndex, 0, reorderItem);

		const updates = newItems.map((item: any, i: number) => ({id: item.id, rank: i}));
		if (isAuthenticated) {
			prepareReorderUpdates(updates);
			dispatch(entriesUpdateMany(updates));
		} else {
			dispatch(entriesUpdateMany(updates));
		}
	};

	// TODO: duplicate code
	const reorder = (reorderItem: any, targetItem: any) => {
		const newItems: any = [...data];
		const draggingIndex = data.findIndex((item) => item.id === reorderItem.id);
		const targetIndex = data.findIndex((item) => item.id === targetItem.id);

		newItems.splice(draggingIndex, 1);
		newItems.splice(targetIndex, 0, reorderItem);

		const updates = newItems.map((item: any, i: number) => ({id: item.id, rank: i}));
		if (isAuthenticated) {
			entryUpdateMutation(updates);
		} else {
			dispatch(entriesUpdateMany(updates));
		}
	};

	const prepareReorderUpdates = (updates: Partial<Entry>[]) => {
		updatesRef.current = updates;
	};

	const pushReorderUpdates = () => {
		if (updatesRef.current.length) {
			const updates: Partial<Entry>[] = updatesRef.current.map((item: any, i: number) => ({id: item.id, rank: i}));
			entryUpdateMutation(updates);
			updatesRef.current = [];
		}
	};

	const handleDragCellTouchHold = (item: any) => {
		setTouchItem(item);
	};

	const handleRowMenuReorderClick = (item: any) => {
		handleDragCellTouchHold(item);
		rowMenuProps.onClose();
	};

	const handleRowMoveToTopClick = (item: any) => {
		const firstRowItem = data[0];
		reorder(item ,firstRowItem);
		rowMenuProps.onClose();
	};

	const handleReorderRowTouchHold = () => {
		setTouchItem(null);
	};

	/** TOUCH EVENT:
	 * while reordering is not in progress, set a row into reordering state by holding down touch on its title cell for [timeout]
	 * unless the page is scrolled within the [timeout]
	 */
	const handleDragCellTouchStart = (e: any, item: any) => {
		if (touchItem) {
			return;
		}
		e.stopPropagation();
		hasWindowScrolledRef.current = false;
		rowHoldTimeout.current = setTimeout(() => {
			if (!hasWindowScrolledRef.current) {
				handleDragCellTouchHold(item);
			}
		}, REORDER_ACTIVATE_TOUCH_TIMEOUT);
	};
	const handleDragCellTouchEnd = (e: any, item: any) => {
		if (rowHoldTimeout.current) {
			clearTimeout(rowHoldTimeout.current);
		}
	};

	/** TOUCH EVENT:
	 * while reordering is in progress, cancel reordering by holding down touch on the same row after [timeout]
	 * unless touch is ended within [timeout]
	 * or the page is scrolled within [timeout]
	 */
	const handleRowTouchStart = (e: React.TouchEvent<HTMLDivElement>, item: any) => {
		if (!touchItem) {
			return;
		}
		e.stopPropagation();
		hasWindowScrolledRef.current = false;
		rowHoldTimeout.current = setTimeout(() => {
			if (!hasWindowScrolledRef.current) {
				if (touchItem?.id === item.id) {
					handleReorderRowTouchHold();
				}
			}
		}, REORDER_ACTIVATE_TOUCH_TIMEOUT);
	};

	/** TOUCH EVENT:
	 * while reordering is in progress, assign a new rank to the active row by touching another row
	 * unless the page has been scrolled since touch start
	 */
	const handleRowTouchEnd = (e: React.TouchEvent<HTMLDivElement>, item: any) => {
		e.stopPropagation();
		if (rowHoldTimeout.current) {
			clearTimeout(rowHoldTimeout.current);
		}
		if (touchItem && item.id !== touchItem.id && !hasWindowScrolledRef.current) {
			applyLocalOrder(e, item);
		}
	};

	/** MOUSE EVENT:
	 * set a row into reordering state by holding down mouse on its title cell
	 */
	const handleDragCellMouseDown = (e: any, item: any) => {
		e.stopPropagation();
		if (touchItem) {
			return;
		}
		setDragItem(item);
	};

	/** MOUSE EVENT:
	 * while reordering is in progress, assign a new rank to the active row by moving the cursor over another row
	 */
	const handleRowMouseEnter = (e: any, targetItem: any) => {
		if (dragItem) {
			applyLocalOrder(e, targetItem);
		}
	};

	const reorderItem = dragItem ?? touchItem;

	const classes = {
		btnCell: css`
			width: 56px;
			padding: 16px 8px !important;
		`,
	};

	const theme = useTheme();

	return (
		<>
			<TableContainer sx={{pb: 16}}>
				<Table aria-label="simple table">
					<TableHead>
						<TableRow>
							{propertyDefinitions.map((propertyDefinition) => <TableCell key={propertyDefinition.id} sx={{color: 'text.secondary'}}>{propertyDefinition.label}</TableCell>)}
							<TableCell align='right'></TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{data.map((row) => <TableRow
							key={row.id}
							sx={{ '&:last-child td, &:last-child th': { border: 0 }, userSelect: 'none'}}
							onMouseEnter={(e) => handleRowMouseEnter(e, row)}
							onTouchEnd={(e) => handleRowTouchEnd(e, row)}
							onTouchStart={(e) => handleRowTouchStart(e, row)}
							selected={row.id === reorderItem?.id}
						>
							{propertyDefinitions.map((def, i) => i === 0 ?
								<TitleCellContent
									key={def.id}
									def={def}
									value={row.data[def.slug]}
									onTouchStart={(e) => handleDragCellTouchStart(e, row)}
									onTouchEnd={(e) => handleDragCellTouchEnd(e, row)}
									onMouseDown={(e) => handleDragCellMouseDown(e, row)}
									disabled={disableReordering}
								/> :
								<CellContent
									key={def.slug}
									def={def}
									value={row.data[def.slug]}
									disabled={disableReordering}
								/>)
							}
							{rowMenuProps.onMenuBtnClick && <TableCell className={classes.btnCell}><IconButton onClick={(e) => rowMenuProps.onMenuBtnClick!(row, e.target as Element)}>
								<MoreVertIcon sx={{color: 'text.secondary'}} />
							</IconButton></TableCell>}
						</TableRow>)}
					</TableBody>
				</Table>
				{tableFooterContent}
			</TableContainer>
			<EntryMenu
				anchorEl={rowMenuProps.anchorEl}
				onClose={rowMenuProps.onClose}
				item={rowMenuProps.item}
				options={rowMenuProps.options}
				onRowReorderClick={handleRowMenuReorderClick}
				onRowMoveToTopClick={handleRowMoveToTopClick}
			/>
		</>
	);
}

export default EntriesTable;
