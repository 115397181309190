import * as React from 'react';
import {useCallback, useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import {Box, DialogTitle} from '@mui/material';
import {
	EntityType,
	EntryProperty,
	PropertyDefinition,
	PropertyDefinitionBelongsTo,
	PropertyDefinitionHas,
	RepositoryEntry,
} from '../types';
import {
	PROPERTY_DEFINITION_TYPES_TO_ENTRY_PROPERTY_TYPES,
	REPOSITORY_ENTRIES_VIEW_ADD_ENTRY_REPOSITORY_OPTIONS
} from '../constants';
import FormTemplate from './FormTemplate';
import {assignRandomId, initFormData, populateSelectOptionsRepositoryEntries,} from '../common/util';
import {entriesAddMany, selectAllRepositoryEntries, selectEntryReadableValues,} from '../containers/contentSlice';
import {useAppDispatch, useAppSelector} from '../hooks';
import {selectIsAuthenticated} from '../containers/authSlice';
import {useRepositoryEntryCreateMutation} from '../services/repositoryEntries';
import {
	addFormRepositoryOptionsSetState,
	formDataSetState,
	formInit,
	selectAddFormLinkedEntryId,
	selectAddFormLinkedProperty,
	selectAddFormRepository,
	selectAddFormRepositoryEntriesMaxRank,
	selectAddFormRepositoryPropertyDefinitions,
	selectFormData,
	selectFormSelectOptions,
	selectFormTemplateProperties,
} from '../containers/formDataSlice';
import {useAddEntryForm} from './AddEntryForm';

export type AddFormData = any;

const populateEntryProps = (repositoryId: string): Partial<RepositoryEntry> => {
	return assignRandomId({
		type: EntityType.RepositoryEntry,
		parentId: repositoryId,
		repositoryId,
	});
};

const populateEntryPropertyData = (def: PropertyDefinition, entryId: string, value: any): EntryProperty => {
	const props = assignRandomId({
		type: PROPERTY_DEFINITION_TYPES_TO_ENTRY_PROPERTY_TYPES[def.type],
		repositoryId: def.parentId,
		propertyDefinitionId: def.id,
		slug: def.slug,
		parentId: entryId,
		value, // remote entry id
	});
	if (def.type === EntityType.PropertyDefinitionBelongsTo) {
		return {
			...props,
			targetRepositoryId: (def as PropertyDefinitionBelongsTo).targetRepositoryId
		};
	} else if (def.type === EntityType.PropertyDefinitionHas) {
		return assignRandomId({
			type: EntityType.EntryPropertyBelongsTo,
			repositoryId: (def as PropertyDefinitionHas).targetRepositoryId,
			propertyDefinitionId: 'IS THIS NEEDED?',
			targetRepositoryId: (def as PropertyDefinitionHas).parentId,
			slug: (def as PropertyDefinitionHas).targetPropertySlug, // TODO: it should be verified if such property exists on target repository side before inserting the record
			parentId: value,
			value: entryId,
		});
	} else {
		return props;
	}
};

export const useAddRepositoryEntryForm = () => {
	const dispatch = useAppDispatch();
	const formData = useAppSelector(selectFormData);
	const isAuthenticated = useAppSelector(selectIsAuthenticated);
	const [repositoryEntryCreateMutation] = useRepositoryEntryCreateMutation();
	const addFormRepository = useAppSelector(selectAddFormRepository);
	const addFormPropertyDefinitions = useAppSelector(selectAddFormRepositoryPropertyDefinitions);
	const currentMaxRank = useAppSelector(selectAddFormRepositoryEntriesMaxRank);
	const addFormLinkedProperty = useAppSelector(selectAddFormLinkedProperty);
	const addFormLinkedEntryId = useAppSelector(selectAddFormLinkedEntryId);

	const {closeAddDialog} = useAddEntryForm();

	const handleAddDialogValueChange = useCallback((key: string, value: any) => {
		if (value !== ' ') {
			dispatch(formDataSetState({
				...formData,
				[key]: value,
			}));
		}
	}, [formData]);

	const handleAddDialogSubmit = (payload: AddFormData) => {
		const addFormRepositoryId = addFormRepository!.id;
		const {...propertyValues} = payload;
		const entry = {
			...populateEntryProps(addFormRepositoryId),
			rank: currentMaxRank + 1
		};
		const entryProperties: EntryProperty[] = addFormPropertyDefinitions
			.filter((def) => def.type !== EntityType.PropertyDefinitionHas)
			.reduce((acc: EntryProperty[], def) => {
				if (def.type === EntityType.PropertyDefinitionBelongsTo) {
					const values = propertyValues[def.slug];
					const belongsToValues = values.map((value: string) => populateEntryPropertyData(def, entry.id!, value));
					return [...acc, ...belongsToValues];
				} else {
					return [...acc, populateEntryPropertyData(def, entry.id!, propertyValues[def.slug])];
				}
			}, []);

		/**
		 * Special area for creating linked entry:
		 */
		const foreignEntryProperties = [];
		if (addFormLinkedProperty && addFormLinkedEntryId) {
			/**
			 * In case of creating linked entry via 'belongsTo' property, the linking property payload should be posted to back-end
			 * via 'foreignEntryProperties', because regular properties get 'parentId' overwritten by freshly created entry id.
			 */
			if (addFormLinkedProperty.type === EntityType.PropertyDefinitionBelongsTo) {
				foreignEntryProperties.push(populateEntryPropertyData(addFormLinkedProperty, addFormLinkedEntryId, entry.id));
			}
			/**
			 * In case of creating linked entry via 'has' property, the property payload should be posted to back-end via regular 'properties',
			 * because the target entry will be freshly created, and back-end will have to overwrite the 'parentId'.
			 */
			if (addFormLinkedProperty.type === EntityType.PropertyDefinitionHas) {
				entryProperties.push(populateEntryPropertyData(addFormLinkedProperty, addFormLinkedEntryId, entry.id));
			}
		}
		if (isAuthenticated) {
			repositoryEntryCreateMutation({
				entry: entry,
				properties: entryProperties,
				foreignEntryProperties,
			});
		} else {
			dispatch(entriesAddMany([entry, ...entryProperties, ...foreignEntryProperties]));
		}
		closeAddDialog();
	};

	return {handleAddDialogValueChange, handleAddDialogSubmit};
};

interface DialogProps {
	open: boolean;
	onClose: () => void;
	onSubmit: (formData: AddFormData) => void;
	title: string;
	children: React.ReactNode;
}

interface Props {
	onChange: (key: string, value: any) => void;
}

export const AddRepositoryEntryFormDialog: React.FC<DialogProps> = ({ open, onClose, onSubmit, title, children }) => {
	const dispatch = useAppDispatch();
	const formData = useAppSelector(selectFormData);
	const [dialogTitle, setDialogTitle] = useState<string>('');

	useEffect(() => {
		dispatch(addFormRepositoryOptionsSetState(REPOSITORY_ENTRIES_VIEW_ADD_ENTRY_REPOSITORY_OPTIONS));
	}, []);

	// cache content to prevent it from disappearing during dialog fade out
	useEffect(() => {
		if (open) {
			setDialogTitle(title);
		}
	}, [open, title]);

	const handleSubmit = (e: React.FormEvent) => {
		e.preventDefault();
		onSubmit(formData);
	};

	return <Dialog open={open} onClose={onClose} maxWidth='xs' sx={{
		'& .MuiDialog-container': {
			'& .MuiPaper-root': {
				width: '100%',
			},
		},
	}}>
		<Box display='flex' justifyContent='space-between' alignItems='center'>
			<DialogTitle>{dialogTitle}</DialogTitle>
		</Box>
		<form onSubmit={handleSubmit} autoComplete='off'>
			<DialogContent>
				{children}
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose}>Cancel</Button>
				<Button type='submit' variant="contained">Add </Button>
			</DialogActions>
		</form>
	</Dialog>;
};

function AddRepositoryEntryForm({ onChange }: Props) {
	const dispatch = useAppDispatch();
	const formData = useAppSelector(selectFormData);
	const selectOptions = useAppSelector(selectFormSelectOptions);
	const formTemplateProperties = useAppSelector(selectFormTemplateProperties);
	const addFormRepository = useAppSelector(selectAddFormRepository);
	const allRepositoryEntries = useAppSelector(selectAllRepositoryEntries);
	const entryReadableValues = useAppSelector(selectEntryReadableValues);
	const addFormRepositoryPropertyDefinitions = useAppSelector(selectAddFormRepositoryPropertyDefinitions);

	const initForm = () => {
		const blankFormData = initFormData(addFormRepositoryPropertyDefinitions);
		const formSelectOptions = populateSelectOptionsRepositoryEntries(addFormRepositoryPropertyDefinitions, allRepositoryEntries, entryReadableValues);
		const formData = { ...blankFormData };
		dispatch(formInit({
			formTemplateProperties: addFormRepositoryPropertyDefinitions,
			formSelectOptions,
			formData,
		}));
	};

	useEffect(() => {
		if (addFormRepository) {
			initForm();
		}
	}, [addFormRepository]);

	return <FormTemplate
		propertyDefinitions={formTemplateProperties}
		formData={formData}
		onValueChange={onChange}
		selectOptions={selectOptions}
	/>;
}

export default AddRepositoryEntryForm;
