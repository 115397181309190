import React from 'react';
import {Fab} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

interface Props {
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  disabled?: boolean;
}

const AddButton = ({ onClick, disabled }: Props) => <Fab size="medium" onClick={onClick} color='primary' disabled={disabled}>
	<AddIcon />
</Fab>;

export default AddButton;
