import React, {useEffect, useRef, useState} from 'react';
import {Box} from '@mui/material';
import useAuth from './Auth';

const APPEAR_TIMEOUT = 2000;

const GuestModeBanner = () => {
	const {isAuthenticated, isAuthenticating} = useAuth();
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const timeoutRef = useRef<NodeJS.Timeout | null>(null);

	useEffect(() => {
		if (timeoutRef.current) {
			clearTimeout(timeoutRef.current);
		}
		if (!isAuthenticating && !isAuthenticated) {
			timeoutRef.current = setTimeout(() => {
				setIsOpen(true);
			}, APPEAR_TIMEOUT);
		}
	}, [isAuthenticated, isAuthenticating]);

	return isOpen ? <Box sx={{pl: 2, color: 'primary.contrastText', backgroundColor: 'primary.dark', position: 'fixed', bottom: 0, left: 0, width: '100%'}}>Guest mode</Box> : null;
};

export default GuestModeBanner;
