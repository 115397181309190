import React from 'react';
import {
	EntityType,
	PropertyDefinition,
	PropertyDefinitionBelongsTo,
	BelongsToPopulatedValue,
	RepositoryEntryData, PropertyDefinitionHas,
} from '../types';
import {Box, IconButton, Paper, Typography} from '@mui/material';
import {entryPropertyGetReadableValue} from '../common/util';
import AddIcon from '@mui/icons-material/Add';
import EntriesList, {EntriesListItem} from './EntriesList';

interface Props {
  propertyDefinitions: PropertyDefinition[];
  entryData: RepositoryEntryData;
  onAddEntryClick: (def: PropertyDefinitionBelongsTo | PropertyDefinitionHas) => void;
}

interface EntryPropertyProps {
  def: PropertyDefinition;
  value: string;
}

interface EntryPropertyBelongsToProps {
  def: PropertyDefinition;
	values: BelongsToPopulatedValue[];
	onAddBtnClick: (def: PropertyDefinitionBelongsTo | PropertyDefinitionHas) => void;
}

const EntryPropertyTextBlock = ({def, value}: EntryPropertyProps) => {
	return <Box key={def.slug} sx={{cursor:'pointer'}}>
		<Typography variant='body2' sx={{color: 'grey', mt: 2, mb: 1}}>{def.label}</Typography>
		<Typography variant='body1'>
			{entryPropertyGetReadableValue(def, value)}
		</Typography>
	</Box>;
};

const EntryPropertyBelongsToBlock = ({def, values, onAddBtnClick}: EntryPropertyBelongsToProps) => {
	return <Box display='flex' flexDirection='column'>
		<Box display='flex' alignItems='center'>
			<Box>
				<Typography variant='body2' sx={{color: 'grey', mt: 2, mb: 1}}>
					{def.label}
				</Typography>
			</Box>
		</Box>
		<Paper sx={{mb: 1}}>
			<EntriesList>
				{values
					.map(({id, label}) => <EntriesListItem
						key={id}
						id={id}
						label={label}
					/>)}
			</EntriesList>
		</Paper>
		<Box>
			<IconButton
				onClick={onAddBtnClick ? () => onAddBtnClick(def as PropertyDefinitionBelongsTo) : undefined}
			>
				<AddIcon />
			</IconButton>
		</Box>
	</Box>;
};

function EntryProperties({propertyDefinitions, entryData, onAddEntryClick}: Props) {
	return <div>
		{propertyDefinitions.map((def) => {
			if (def.type === EntityType.PropertyDefinitionHas || def.type === EntityType.PropertyDefinitionBelongsTo && (def as PropertyDefinitionBelongsTo).allowMany) {
				return <EntryPropertyBelongsToBlock
					key={def.id}
					def={def}
					values={entryData[def.slug] as BelongsToPopulatedValue[]}
					onAddBtnClick={onAddEntryClick}
				/>;
			}
			return <EntryPropertyTextBlock
				key={def.id}
				def={def}
				value={entryData[def.slug] as string}
			/>;
		})}
	</div>;
}

export default EntryProperties;
