import React from 'react';
import config from '../common/config';
import {
	Avatar,
	Box,
	Divider,
	IconButton,
	ListItemIcon,
	Menu,
	MenuItem,
	Tooltip,
	useMediaQuery
} from '@mui/material';
import {Theme} from '../types';
import {css} from '@emotion/css';
import {Logout} from '@mui/icons-material';
import MaterialUISwitch from './MuiThemeSwitch';
import {useThemeContext} from '../ThemeProvider';
import useAuth from './Auth';
import KeyIcon from '@mui/icons-material/Key';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useAppDispatch } from '../hooks';
import { isIntroDialogOpenSetState } from '../containers/authSlice';

const classes = {
	root: css`
		font-size: 18px;
		letter-spacing: 9px;
		white-space: nowrap;
	`,
	sm: css`
		font-size: 16px;
		letter-spacing: 8px;
		white-space: nowrap;
  `};

const AccountMenu = () => {
	const isSmScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const {toggleDarkMode, isDarkModeOn} = useThemeContext();
	const {userProfile, logout, isAuthenticated, loginWithRedirect} = useAuth();
	const dispatch = useAppDispatch();

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const handleLogoutClick = () => {
		logout();
	};
	const onDataPresetsClick = () => {
		setAnchorEl(null);
		dispatch(isIntroDialogOpenSetState(true));
	};

	let initials = '';
	if (isAuthenticated) {
		initials = userProfile.firstName && userProfile.lastName 
			? `${userProfile.firstName[0]}${userProfile.lastName[0]}`.toUpperCase() 
			: `${userProfile.name[0]}${userProfile.name[1]}`.toUpperCase(); 
	}

	return <>
		<Box display='flex' justifyContent='right' alignItems='center'>
			<div className={isSmScreen ? classes.sm : classes.root}>{config.appName}</div>
			<Tooltip title="Account settings">
				<IconButton
					onClick={handleClick}
					aria-controls={open ? 'account-menu' : undefined}
					aria-haspopup="true"
					aria-expanded={open ? 'true' : undefined}
				>
					<img src='/logo.png' width='32' />
				</IconButton>
			</Tooltip>
		</Box>
		<Menu
			anchorEl={anchorEl}
			id="account-menu"
			open={open}
			onClose={handleClose}
		>
			{isAuthenticated && <MenuItem onClick={handleClose}>
				<Avatar sx={{mr: 1}}>{initials}</Avatar>
				{userProfile.name}
				<br/>
				{userProfile.companyName}
			</MenuItem>}
			{isAuthenticated && <Divider />}
			{isAuthenticated ? <MenuItem onClick={handleLogoutClick}>
				<ListItemIcon>
					<Logout fontSize="small" />
				</ListItemIcon>
				Sign out
			</MenuItem> : <MenuItem onClick={() => loginWithRedirect()}>
				<ListItemIcon>
					<KeyIcon fontSize="small"/>
				</ListItemIcon>
				Sign in
			</MenuItem>}
			<Divider />
			<MenuItem onClick={onDataPresetsClick}>
				<ListItemIcon>
					<HelpOutlineIcon fontSize="small"/>
				</ListItemIcon>
				Data presets
			</MenuItem>
			<MenuItem onClick={() => toggleDarkMode()}>
				<MaterialUISwitch checked={isDarkModeOn} />
				Switch to {isDarkModeOn ? 'light' : 'dark'}
			</MenuItem>
		</Menu>
	</>;
};

export default AccountMenu;
