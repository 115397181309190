import {createAction, createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {
	EntityType,
	Entry,
	EntryPropertyBelongsTo,
	EntryPropertyString,
	PropertyDefinitionStringEntry,
	Repository, RepositoryEntry,
} from '../types';
import {RootState} from '../store';
import {sortEntryByRank} from '../common/util';
import {recordsApi} from '../services/records';
import {repositoryEntriesApi} from '../services/repositoryEntries';

interface ContentState {
	locationId: string | null;
	entries: Entry[];
	isLocationEntryLoading: boolean;
	isLocationEntryLoaded: boolean;
	locationEntry: Entry | null;
	isRepositoryLoading: boolean;
	repository: Repository | null;
	isRepositoryEntryLoading: boolean;
	repositoryEntry: RepositoryEntry | null;
	clipboardEntries: Entry[];
}

const initialState: ContentState = {
	locationId: null,
	entries: [],
	isLocationEntryLoading: false,
	isLocationEntryLoaded: false,
	locationEntry: null,
	isRepositoryLoading: false,
	repository: null,
	isRepositoryEntryLoading: false,
	repositoryEntry: null,
	clipboardEntries: [],
};

export const contentSlice = createSlice({
	name: 'content',
	initialState,
	reducers: {
		entriesSetState(state: ContentState, action) {
			state.entries = action.payload;
			return state;
		},
		entriesUpdateMany(state: ContentState, action) {
			const updatesByIds = action.payload.reduce((acc: any, {id, ...update}: any) => acc.set(id, update), new Map());
			state.entries = state.entries.map((entry) => {
				const update = updatesByIds.get(entry.id);
				return !update ? entry : { ...entry, ...update };
			});
		},
		entriesAddMany(state: ContentState, action) {
			state.entries = [...state.entries, ...action.payload];
		},
		entriesAppendMany(state: ContentState, action) {
			const mergedEntries = [...state.entries, ...action.payload];
			const updatedEntriesMap = new Map(mergedEntries.map((entry) => [entry.id, entry]));
			state.entries = Array.from(updatedEntriesMap).map(([,entry]) => entry);
		},
		entriesDeleteMany(state: ContentState, action: PayloadAction<string[]>) {
			state.entries = state.entries.filter((entry) => !action.payload.includes(entry.id));
		},
		clipboardEntriesAddById(state: ContentState, action) {
			const clippedEntry = state.entries.find((entry) => action.payload === entry.id);
			state.clipboardEntries = clippedEntry ? [...state.clipboardEntries, clippedEntry] : state.clipboardEntries;
		},
		clipboardEntriesResetState(state: ContentState) {
			state.clipboardEntries = [];
		},
		locationResetState(state: ContentState, action: PayloadAction<string | null>) {
			state.locationId = action.payload;
			state.locationEntry = null;
			state.isLocationEntryLoading = false;
			state.isLocationEntryLoaded = false;
			state.repository = null;
			state.isRepositoryLoading = false;
			state.repositoryEntry= null;
			state.isRepositoryEntryLoading = false;
		},
		repositoryLoading(state: ContentState, action: PayloadAction<void>) {
			state.isRepositoryLoading = true;
		},
		repositoryLoaded(state: ContentState, action: PayloadAction<Repository>) {
			state.repository = action.payload;
			state.isRepositoryLoading = false;
		},
		repositoryEntryLoading(state: ContentState, action: PayloadAction<void>) {
			state.isRepositoryEntryLoading = true;
		},
		repositoryEntryLoaded(state: ContentState, action: PayloadAction<RepositoryEntry>) {
			state.repositoryEntry = action.payload;
			state.isRepositoryEntryLoading = false;
		},
		locationEntryLoading(state: ContentState, action: PayloadAction<void>) {
			state.isLocationEntryLoading = true;
			state.isLocationEntryLoaded = false;
		},
		locationEntryLoaded(state: ContentState, action: PayloadAction<Entry | null>) {
			state.locationEntry = action.payload;
			state.isLocationEntryLoading = false;
			state.isLocationEntryLoaded = true;
		},
	},
	extraReducers: (builder) => {
		builder
			.addMatcher(recordsApi.endpoints.loadEntry.matchFulfilled, (state: ContentState, action: PayloadAction<Entry[]>) => {
				const [locationEntry, ...childEntries] = action.payload;
				contentSlice.caseReducers.locationEntryLoaded(state, contentSlice.actions.locationEntryLoaded(locationEntry));
				contentSlice.caseReducers.entriesAppendMany(state, contentSlice.actions.entriesAppendMany(childEntries));
			})
			.addMatcher(repositoryEntriesApi.endpoints.loadRepository.matchFulfilled, (state: ContentState, action: PayloadAction<Entry[]>) => {
				contentSlice.caseReducers.repositoryLoaded(state, contentSlice.actions.repositoryLoaded(action.payload[0] as Repository));
				contentSlice.caseReducers.entriesAppendMany(state, action);
			})
			.addMatcher(repositoryEntriesApi.endpoints.loadRepositoryEntry.matchFulfilled, (state: ContentState, action: PayloadAction<Entry[]>) => {
				contentSlice.caseReducers.repositoryEntryLoaded(state, contentSlice.actions.repositoryEntryLoaded(action.payload[0] as RepositoryEntry));
				contentSlice.caseReducers.entriesAppendMany(state, action);
			})
			.addMatcher(recordsApi.endpoints.entryCreate.matchFulfilled, (state: ContentState, action: PayloadAction<Entry[]>) => {
				const updatesByFakeIds = (action.payload as Entry[]).reduce((acc, syncedEntry) => {
					acc.set(syncedEntry.fakeId as string, syncedEntry);
					return acc;
				}, new Map<string, Entry>());
				state.entries = state.entries.map(entry => {
					const updatedEntry = updatesByFakeIds.get(entry.id);
					return updatedEntry ? updatedEntry : entry;
				});
			})
			.addMatcher(repositoryEntriesApi.endpoints.repositoryEntryCreate.matchFulfilled, (state: ContentState, action: PayloadAction<Entry[]>) => {
				const updatesByFakeIds = (action.payload as Entry[]).reduce((acc, syncedEntry) => {
					acc.set(syncedEntry.fakeId as string, syncedEntry);
					return acc;
				}, new Map<string, Entry>());
				state.entries = state.entries.map(entry => {
					const updatedEntry = updatesByFakeIds.get(entry.id);
					return updatedEntry ? updatedEntry : entry;
				});
			})
			.addMatcher(repositoryEntriesApi.endpoints.repositoryEntryUpdate.matchFulfilled, (state: ContentState, action: PayloadAction<{
				createdProperties: Entry[];
				updatedProperties: Entry[];
				deletedPropertyIds: string[];
			}>) => {
				const updatesByIds = (action.payload.updatedProperties as Entry[]).reduce((acc, updatedEntry) => {
					acc.set(updatedEntry.id as string, updatedEntry);
					return acc;
				}, new Map<string, Entry>());
				state.entries = [...state.entries.filter(entry => !action.payload.deletedPropertyIds.includes((entry.id))).map(entry => {
					const updatedEntry = updatesByIds.get(entry.id);
					return updatedEntry ? updatedEntry : entry;
				}), ...action.payload.createdProperties];
			})
			.addMatcher(recordsApi.endpoints.entryDelete.matchFulfilled, (state: ContentState, action: PayloadAction<{deletedIds: string[]}>) => {
				contentSlice.caseReducers.entriesDeleteMany(state, { ...action, payload: action.payload.deletedIds });
			})
			.addMatcher(repositoryEntriesApi.endpoints.repositoryEntryArchive.matchFulfilled, (state: ContentState, action: PayloadAction<Entry[]>) => {
				contentSlice.caseReducers.entriesUpdateMany(state, contentSlice.actions.entriesUpdateMany(action.payload));
			})
			.addMatcher(repositoryEntriesApi.endpoints.repositoryEntryUnarchive.matchFulfilled, (state: ContentState, action: PayloadAction<Entry[]>) => {
				contentSlice.caseReducers.entriesUpdateMany(state, contentSlice.actions.entriesUpdateMany(action.payload));
			});
	},
});

export const {
	entriesSetState,
	entriesUpdateMany,
	entriesAddMany,
	entriesDeleteMany,
	clipboardEntriesAddById,
	clipboardEntriesResetState,
	locationResetState,
	repositoryLoading,
	repositoryEntryLoading,
	repositoryEntryLoaded,
	locationEntryLoading,
	locationEntryLoaded,
	repositoryLoaded,
} = contentSlice.actions;

export const selectContentState = (state: RootState) => state.content;

export const selectLocationId = createSelector([selectContentState], (state) => state.locationId);

export const selectAllEntries = createSelector([selectContentState], (state) => state.entries);

export const selectAllRepositoryEntries = createSelector([selectAllEntries], (allEntries) => allEntries.filter((entry) => entry.type === EntityType.RepositoryEntry) as RepositoryEntry[]);

export const selectEntryReadableValues = createSelector([selectAllEntries], (allEntries) => {
	const allPropertyDefinitionStrings = allEntries
		.filter((entry) => entry.type === EntityType.PropertyDefinitionString) as PropertyDefinitionStringEntry[];
	const firstStringPropertyIdsPerRepository = allPropertyDefinitionStrings
		.sort(sortEntryByRank)
		.reduce((acc, stringPropertyDef) => acc.get(stringPropertyDef.parentId) ? acc : acc.set(stringPropertyDef.parentId, stringPropertyDef.id), new Map<string, string>());
	const allEntryPropertyStrings = allEntries
		.filter((entry) => entry.type === EntityType.EntryPropertyString) as EntryPropertyString[];
	return allEntryPropertyStrings
		.reduce((acc, propertyValue) => {
			const repositoryFirstStringPropertySlug = firstStringPropertyIdsPerRepository.get(propertyValue.repositoryId);
			if (
				repositoryFirstStringPropertySlug && 
				repositoryFirstStringPropertySlug === propertyValue.propertyDefinitionId
			) {
				acc.set(propertyValue.parentId, propertyValue.value);
			}
			return acc;
		}, new Map<string, string>());
});

export const selectClipboardEntries = (state: RootState) => state.content.clipboardEntries;

export const selectClipboardEntriesSet = createSelector([selectClipboardEntries], (clipboardEntries) => new Set(clipboardEntries.map(({id}) => id)));

export const selectLocationEntry = createSelector([selectContentState], (state) => state.locationEntry);

export const selectLocationEntryLocal = createSelector([selectAllEntries, selectLocationId], (entries, locationId) => entries.find((entry) => entry.id === locationId || (!entry.companyId && entry.fakeId === locationId)) ?? null);

export const selectIsLocationEntryLoading = createSelector([selectContentState], (state) => state.isLocationEntryLoading);

export const selectIsLocationEntryLoaded = createSelector([selectContentState], (state) => state.isLocationEntryLoaded);

export const selectLocationAllEntries = createSelector([selectAllEntries, selectLocationId, selectIsLocationEntryLoaded, selectClipboardEntriesSet], (entries, locationId, isLocationEntryLoaded, clipboardEntriesSet) => isLocationEntryLoaded ? entries.filter((entry) => entry.parentId === locationId && !clipboardEntriesSet.has(entry.id)) : []);

export const selectAllRepositories = createSelector([selectAllEntries], (entries) => entries.filter((entry) => entry.type === EntityType.Repository) as Repository[]);

export const repositoryEntryUpdateLocalAction = createAction<any>('repositoryEntries/repositoryEntryUpdateLocal');

export const repositoryEntryArchiveLocalAction = createAction<any>('repositoryEntries/repositoryEntryArchiveLocal');

export const repositoryEntryUnarchiveLocalAction = createAction<any>('repositoryEntries/repositoryEntryUnarchiveLocal');

export const recordDeleteLocalAction = createAction<any>('records/recordDeleteLocal');

export default contentSlice.reducer;
