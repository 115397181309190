import {
	EntityType,
	Entry,
	EntryProperty,
	EntryPropertyBelongsTo,
	PopulatedEntry,
	PropertyDefinition,
	PropertyDefinitionBoolean,
	PropertyDefinitionHas,
	Repository,
	RepositoryEntry,
	FormSelectOptions,
	RepositoryEntryData,
	PropertyDefinitionBelongsTo
} from '../types';
import {FormData} from '../components/EditEntryForm';
import {nanoid} from 'nanoid';
import {
	ENTRY_PROPERTY_TYPES,
	PROPERTY_DEFINITION_TYPES,
	REPOSITORIES_BY_ENTITY_TYPE,
	SYSTEM_TEMPLATE_PROPERTIES,
} from '../constants';

const getDefaultValue = (def: PropertyDefinition) => {
	switch (def.type) {
	case EntityType.PropertyDefinitionBelongsTo:
	case EntityType.PropertyDefinitionHas:
		return [];
	case EntityType.PropertyDefinitionBoolean:
		return (def as PropertyDefinitionBoolean).defaultChecked || false;
	default:
		return '';
	}
};

export const initFormData = (propertyDefinitions: PropertyDefinition[], propertyValues: any = {}): FormData => {
	return propertyDefinitions
		.filter(def => def.type !== EntityType.PropertyDefinitionHas)
		.reduce((acc, def) => {
			if (def.type === EntityType.PropertyDefinitionBelongsTo) {
				const rawValues = propertyValues[def.slug] ? propertyValues[def.slug].map(({ value }: { value: string }) => value) : getDefaultValue(def);
				return {...acc, [def.slug]: rawValues};
			}
			return {...acc, [def.slug]: propertyValues[def.slug as keyof Entry] ?? getDefaultValue(def)};
		}, {});
};

export const isCustomRepository = (repositoryId: string) => !Object.values(REPOSITORIES_BY_ENTITY_TYPE).find(({ id }) => repositoryId === id);

export const getTemplatePropertiesBySystemRepositoryId = (repositoryId: string) => SYSTEM_TEMPLATE_PROPERTIES.filter((prop) => prop.parentId === repositoryId);

export const generateRandomId = () => nanoid();

export const assignRandomId = <T extends object>(obj: T): T & { id: string } => {
	const fakeId = generateRandomId();
	return {
		...obj,
		id: fakeId,
		fakeId
	};
};

export const getRepositoryTemplateProperties = (repositoryId: string, allEntries: Entry[]) => allEntries.filter(({parentId, type}) => PROPERTY_DEFINITION_TYPES.includes(type as any) && parentId === repositoryId) as PropertyDefinition[];

export const getEntryProperties = (repositoryEntryId: string, allEntries: Entry[]) => allEntries.filter(({parentId, type}) => ENTRY_PROPERTY_TYPES.includes(type as any) && parentId === repositoryEntryId) as EntryProperty[]; // TODO: rename this to 'getEntryPropertyValues'?

export const getEntryRemoteProperties = (repositoryEntryId: string, allEntries: Entry[]) => allEntries.filter((entry) => [EntityType.EntryPropertyBelongsTo].includes(entry.type as any) && (entry as EntryPropertyBelongsTo).value === repositoryEntryId) as EntryPropertyBelongsTo[]; // populating has property values. TODO: rename this.

export const populateSelectOptionsRepositoryEntries = (propertyDefinitions: PropertyDefinition[], repositoryEntries: RepositoryEntry[], entryReadableValues: Map<string, string>) => {
	return propertyDefinitions
		.filter((prop) => [EntityType.PropertyDefinitionBelongsTo, EntityType.PropertyDefinitionHas].includes(prop.type))
		.reduce((acc, {slug, targetRepositoryId}: any) => {
			const repositoryEntryOptions = repositoryEntries
				.filter((entry) => entry.parentId === targetRepositoryId && !entry.isArchived)
				.map((repositoryEntry) => ({ label: entryReadableValues.get(repositoryEntry.id), value: repositoryEntry.id }));
			const allSelectOptions = [
				...repositoryEntryOptions,
			];
			return {...acc, [slug]: allSelectOptions};
		}, {});
};

export const populateSelectOptionsRepositories = (propertyDefinitions: PropertyDefinition[], allEntries: Entry[]): FormSelectOptions => {
	return propertyDefinitions
		.filter((prop) => prop.type === EntityType.PropertyDefinitionBelongsTo)
		.reduce((acc, {slug, required}: any) => {
			const repositoryOptions = allEntries
				.filter(({ type }) => type === EntityType.Repository)
				.map((repository) => ({ label: (repository as Repository).title , value: repository.id }));
			const allSelectOptions = [
				...repositoryOptions,
			];
			return {...acc, [slug]: allSelectOptions};
		}, {});
};

export const getEditFormTemplatePropertiesForEntityType = (entityType: EntityType) => {
	return TEMPLATES_BY_ENTITY_TYPE[entityType].map((propertyDefinition: PropertyDefinition) => {
		if (propertyDefinition.slug === 'slug') {
			return {...propertyDefinition, disabled: true};
		} else {
			return propertyDefinition;
		}
	});
};

export const entryPropertyGetReadableValue = (propertyDefinition: PropertyDefinition, value: any) => {
	switch (propertyDefinition.type) {
	case EntityType.PropertyDefinitionBoolean:
		return value === true ? 'Yes' : 'No';
	case EntityType.PropertyDefinitionBelongsTo:
	case EntityType.PropertyDefinitionHas:
		return value.map(({ label }: any) => label).join(', ');
	default:
		return value;
	}
};

export const getRepositorySingleUnitLabel = (repository: Repository) => repository.singular ?? '';

/**
 * In the future, when populating entries takes place in the back-end, this method will deprecate as every entry will have 'readableValue' on them.
 */
export const entryGetReadableValue = (entry: Entry, entryReadableValues: Map<string, string>): string => {
	switch (entry.type) {
	case EntityType.EntryPropertyBelongsTo: {
		const targetEntryLabel = entryReadableValues.get(entry.value);
		if (!targetEntryLabel) {
			console.error(`target entry inaccessible: ${entry.value}`);
			return entry.value;
		}
		return targetEntryLabel;
	}
	case EntityType.RepositoryEntry: {
		const entryLabel = entryReadableValues.get(entry.id);
		if (!entryLabel) {
			console.error(`entry inaccessible: ${entry.id}`);
			return entry.id;
		}
		return entryLabel;
	}
	case EntityType.Note:
	case EntityType.Repository: {
		return entry.title;
	}
	case EntityType.EntryPropertyBoolean:
		return entry.value ? 'Yes' : 'No';
	default: {
		if (ENTRY_PROPERTY_TYPES.includes(entry.type as any)) {
			return (entry as EntryProperty).value as string;
		}
		if (PROPERTY_DEFINITION_TYPES.includes(entry.type as any)) {
			return (entry as PropertyDefinition).label as string;
		}
		return 'N/A';
	}
	}
};

const filterPropertiesByDef = (prop: EntryProperty, def: PropertyDefinition) => prop.slug === def.slug;

export const filterPropertiesByHasDef = (prop: EntryPropertyBelongsTo, def: PropertyDefinitionHas) => prop.repositoryId === (def as PropertyDefinitionHas).targetRepositoryId && prop.slug === (def as PropertyDefinitionHas).targetPropertySlug;

export const populateRepositoryEntry = (repositoryEntry: RepositoryEntry, entryProperties: EntryProperty[], entryHasProperties: EntryPropertyBelongsTo[], propertyDefinitions: PropertyDefinition[], idProps: Map<string, string>): PopulatedEntry => {
	const currentEntryProperties = entryProperties.filter(({ parentId }) => parentId === repositoryEntry.id);
	const currentEntryHasProperties = entryHasProperties.filter(({ value }) => value === repositoryEntry.id);

	const dataObject: RepositoryEntryData = propertyDefinitions.reduce((acc, def) => {
		switch (def.type) {
		case EntityType.PropertyDefinitionHas: {
			const fieldProperties = currentEntryHasProperties
				.filter((prop) => filterPropertiesByHasDef(prop, def as PropertyDefinitionHas))
				.map((prop) => ({id: prop.id, value: prop.parentId, label: idProps.get(prop.parentId as string)}));
			return {...acc, [def.slug]: fieldProperties};
		}
		case EntityType.PropertyDefinitionBelongsTo: {
			const fieldProperties = currentEntryProperties
				.filter((prop) => filterPropertiesByDef(prop, def as PropertyDefinitionBelongsTo))
				.map((prop) => ({id: prop.id, value: prop.value, label: idProps.get(prop.value as string)}));
			return {...acc, [def.slug]: fieldProperties};
		}
		default: {
			const [fieldProperty] = currentEntryProperties.filter((prop) => filterPropertiesByDef(prop, def));
			return {...acc, [def.slug]: fieldProperty ? fieldProperty.value : null};
		}
		}
	}, {});
	return {...repositoryEntry, data: dataObject, label: idProps.get(repositoryEntry.id) as string};
};

export const populateEntryData = (entry: Entry): Entry & {data: any} => {
	const propertyDefinitions = TEMPLATES_BY_ENTITY_TYPE[entry.type];
	const dataObject = propertyDefinitions.reduce((acc, def) => {
		const value = entry[def.slug as keyof Entry];
		switch (def.type) {
		case EntityType.PropertyDefinitionBelongsTo: {
			return {...acc, [def.slug]: value ? [{ value }] : []};
		}
		default: {
			return {...acc, [def.slug]: value };
		}
		}
	}, {});
	return {...entry, data: dataObject};
};

export const sortEntryByRank = (a: any, b: any) => {
	if (!Number.isInteger(a.rank) && Number.isInteger(b.rank)) {
		return 1;
	}
	if (Number.isInteger(a.rank) && !Number.isInteger(b.rank)) {
		return -1;
	}
	return (a.rank < b.rank) ? -1 : 1;
};

export const TEMPLATES_BY_ENTITY_TYPE: Record<EntityType, PropertyDefinition[]> = Object.entries(REPOSITORIES_BY_ENTITY_TYPE).reduce((acc, [entityType, repository]) => {
	return {...acc, [entityType]: getTemplatePropertiesBySystemRepositoryId(repository.id)};
}, {} as Record<EntityType, PropertyDefinition[]>);

export function recursiveChildRecordIdsFind(id: string, allEntries: Entry[]): string[] {
	const allIds = [];
	for (const entry of allEntries) {
		// TODO: handle circular dependency?
		if (entry.parentId === id) {
			allIds.push(entry.id, ...recursiveChildRecordIdsFind(entry.id, allEntries));
		}
	}
	return allIds;
}

export function findPropertiesWithUnknownDefinitions(allEntries: Entry[]): string[] {
	const allTemplateProperties = allEntries.filter((entry: any) => PROPERTY_DEFINITION_TYPES.includes(entry.type)) as PropertyDefinition[];
	const allEntryProperties = allEntries.filter((entry: any) => ENTRY_PROPERTY_TYPES.includes(entry.type)) as EntryProperty[];
	return allEntryProperties
		.filter((entryProperty: EntryProperty) =>
			!allTemplateProperties.find((propertyDefinition: PropertyDefinition) =>
				entryProperty.repositoryId === propertyDefinition.parentId && entryProperty.slug === propertyDefinition.slug))
		.map(({id}) => id);
}

export function findEntryPropertiesWithUnknownTargetEntry(allEntries: Entry[]): string[] {
	const allEntryProperties = allEntries.filter((entry: any) => ENTRY_PROPERTY_TYPES.includes(entry.type)) as EntryProperty[];
	const allBelongsToProperties = allEntryProperties.filter((entry: any) => [EntityType.EntryPropertyBelongsTo].includes(entry.type)) as EntryPropertyBelongsTo[];
	const allRepositoryEntriesMap = allEntries.reduce((acc, entry: any) => [EntityType.RepositoryEntry].includes(entry.type) ? acc.set(entry.id, entry) : acc, new Map<string, RepositoryEntry>());
	return allBelongsToProperties
		.filter((entryPropertyBelongsToRecord: EntryPropertyBelongsTo) => {
			const targetEntry = allRepositoryEntriesMap.get(entryPropertyBelongsToRecord.value);
			return !targetEntry || targetEntry.repositoryId !== entryPropertyBelongsToRecord.targetRepositoryId;
		})
		.map(({id}) => id);
}
